import React from "react"
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled, { keyframes } from "styled-components"
import tw from "twin.macro"

import Hand from './hand.svg'
import Mountains from './mountains.svg'
import Camera from './camera.svg'

const App = styled.div``

const Container = styled.div`
  ${tw`container px-8 md:mx-auto`}
`

const Section = styled.div`
  ${tw`py-32 md:pb-64 flex flex-col justify-center`}
`

// const rotate = keyframes`
//   0%   { color: #17acf8; }
//   33%  { color: black; }
//   25%  { color: #ff9e00; }
//   50%  { color: #ff9e00; }
//   75%  { color: #02CB64; }
//   100% { color:red; }
// `;

const wiggle = keyframes`
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  81% { transform: rotate(5deg); }
  82% { transform: rotate(-5deg); }
  83% { transform: rotate(0deg); }
`

const rotate = keyframes`
  0%   { color: white; }
  33%  { color: white; }
  33.5%   { color: black; }
  34%  { color: white; }

  39%  { color: white; }
  39.5%   { color: black; }
  40%  { color: white; }
`;

const BrandTitle = styled.h1`
  ${tw`font-extrabold md:text-6xl text-4xl leading-tight pb-6`}
`;

const BrandSubTitle = styled.h1`
  ${tw`font-medium text-xl md:text-4xl`}
`;

const SpanPink = styled.span`
  // ${tw`text-brand`}
`;

const SpanBlue = styled.span`
  color: #17acf8;
`

const SpanOrange = styled.span`
  color: #ff9e00;
`

const SpanGreen = styled.span`
  // color: #02CB64;
`

const Separator = styled.img`
  width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`

const HelloImage = styled.img`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  ${tw`w-1/4 md:w-48`};

  animation: ${wiggle} 5s infinite;

  &:hover {
    animation: none;
    transform: rotate(10deg);
  }
`

const CameraImage = styled.img`
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  ${tw`w-1/4 md:w-48`};

  &:hover {
    animation: none;
    transform: rotate(10deg);
  }
`

const CameraImageContainer = styled.div`
  ${tw`hidden md:block`};
`

const SectionTitle = styled.h2`
  ${tw`font-extrabold text-sm md:text-2xl text-brand`};
`

const SectionText = styled.p`
  ${tw`font-medium text-2xl md:text-4xl`};
`

const EmailSection = styled.div`
  ${tw`py-32 md:py-64 flex flex-col justify-center items-center`}
`

const Email = styled.a`
  ${tw`font-extrabold text-xl md:text-5xl`};
  &:hover {
    text-decoration: underline;
  }
`

const EmailSubtitle = styled.p`
  ${tw`font-medium md:text-2xl text-center`};
`

const FooterText = styled.p`
  ${tw`font-bold text-xs md:text-base`};
`

const FooterTextRight = styled(FooterText)`
  ${tw`text-right`};
`

const PortfolioItem = styled.div`
  ${tw`pt-8 md:py-10 font-extrabold text-xl md:text-4xl`};

  &:nth-child(n+2) {
    ${tw`pb-8`};
  }
`

const PortfolioTitle = styled.h3`
  ${tw`font-extrabold text-xl md:text-4xl`};
  &:hover {
    text-decoration: underline;

    &:after {
      content: " →";
    }
  }

  &:after {
      content: "→";
  }
`
const PortfolioDetail = styled.p`
  ${tw`font-extrabold text-xs md:text-xl text-brand`};
`

export default ({ children, data }) => (
  <App>
    <Helmet
      siteTitle={data.site.siteMetadata.title}
      title={data.site.siteMetadata.title}
      meta={[
        { name: 'description', content: data.site.siteMetadata.description },
        { name: 'keywords', content: data.site.siteMetadata.keywords },

        { name: 'twitter:card', content: "summary_large_image"},
        { name: 'twitter:site', content: "@loftlabs"},
        { name: 'twitter:creator', content: "@wtsnz"},
        { name: 'twitter:title', content: "Loft Labs Interactive"},
        { name: 'twitter:description', content: data.site.siteMetadata.description},

        { name: 'og:image', content: "https://loftlabs.co/twitter-image.png"},
        { name: 'og:type', content: "website"},
        { name: 'og:creator', content: "@wtsnz"},
        { name: 'og:title', content: "Loft Labs Interactive"},
        { name: 'og:description', content: data.site.siteMetadata.description},
        // { name: '', content: ""},
      ]}
    />
    <Container>
      {/* <Nav /> */}
      <Section>
        <BrandTitle>LOFT LABS <br />INTERACTIVE</BrandTitle>
        <BrandSubTitle>a tiny <SpanPink>software</SpanPink> & <SpanGreen>hardware</SpanGreen> studio.</BrandSubTitle>
        {/* <BrandSubTitle>driven by <SpanBlue>curiosity</SpanBlue>, passionate by <SpanOrange>design</SpanOrange>.</BrandSubTitle> */}
      </Section>
    </Container>
    <Separator src={Mountains} draggable="false" onDragStart={(e) => { e.preventDefault() }} />
    <Container>
      <Section>
        <SectionTitle>WHAT WE MAKE</SectionTitle>
        <SectionText>right now, we're focused on mobile apps that improve video streaming from mobile devices.</SectionText>
        <div class="py-8 flex items-center justify-between">
          <div>
            <PortfolioItem>
              <PortfolioDetail>2020 - CURRENT</PortfolioDetail>
              <a href="https://telecast.camera/">
                <PortfolioTitle>TELECAST CAMERA</PortfolioTitle>
              </a>
            </PortfolioItem>

            <PortfolioItem>
              <PortfolioDetail>2018 - CURRENT</PortfolioDetail>
              <a href="https://obs.camera/">
                <PortfolioTitle>CAMERA FOR OBS STUDIO</PortfolioTitle>
              </a>
            </PortfolioItem>
          </div>
          <CameraImageContainer>
            <CameraImage src={Camera} draggable="false" onDragStart={(e) => { e.preventDefault() }} />
          </CameraImageContainer>
        </div>
        <SectionText>...as for the future - who knows!? we're interested in all sorts! &mdash; let's try and get out of 2020 alive and then we'll see.</SectionText>
        {/* <SectionText>bitcoin • ios </SectionText> */}
      </Section>

      <EmailSection>
        <HelloImage src={Hand} draggable="false" onDragStart={(e) => { e.preventDefault() }} />
        <SectionTitle>SAY HELLO</SectionTitle>
        <Email href="mailto:hello@loftlabs.co">HELLO@LOFTLABS.CO</Email>
        <EmailSubtitle>get in touch, we don't bite. hard.</EmailSubtitle>
      </EmailSection>

      <div class="py-10 flex items-center justify-between">
        <FooterText>© LOFT LABS INTERACTIVE INC.</FooterText>
        <div>
          <FooterTextRight>VANCOUVER. BC.</FooterTextRight>
          <FooterTextRight>WELLINGTON. NZ.</FooterTextRight>
        </div>
      </div>

    </Container>
  </App >
)

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
